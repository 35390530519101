<template>
  <div>
    <div :ref="'each-chart' + index" :id="'each-chart' + index" class="each-chart" :class="{
      'each-chart-for-1': eachData.label.length > 40,
    }" v-for="(eachData, index) in plantGapClosureData" :key="index">
      <div class="primary-color card-tab-act" style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        ">
        <div style="width: fit-content">
          {{ eachData.datasets[0].pillarN }}
          ( {{ eachData.datasets[0].label }} )
        </div>
        <div class="sample-list">
          <div style="display: flex; margin-bottom: 5px; align-items: center">
            <div class="target-maturity-level"></div>
            <span class="sample-span">#Committed Maturity Level</span>
          </div>
          <div style="display: flex; align-items: center">
            <div class="current-maturity-level"></div>
            <span class="sample-span">#Current Maturity Level</span>
          </div>
        </div>
      </div>

      <canvas class="marginClass" :id="'spider-chart' + index" :width="10" :height="9"
        style="background-color: white"></canvas>
      <div class="principle-list-div">
        <div class="principle-list" v-for="principle in eachData.principleList" :key="principle">
          #{{ principle }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import plantChartData from "./data/spider-chart-data";
import Helper from "@/common/helper/index";
export default {
  name: "SpiderChart",
  data() {
    return {
      plantChartData2: {},
      plantChartData1: {},
      // noOfSpider:1
    };
  },
  props: ["plantGapClosureData"],
  created() {
    this.plantChartData2 = Helper.getPlantDataSpider();
    this.plantChartData1 = Helper.getPlantDataSpider();
  },
  mounted() {

    this.plantGapClosureData.sort((a, b) => {
      if (a.datasets[0].pillarId > b.datasets[0].pillarId) return 1;
      else if (a.datasets[0].pillarId < b.datasets[0].pillarId) return -1;

      return 0;
    });
    this.plantGapClosureData.sort((a, b) => {
      if (
        a.datasets[0].label.split(", ")[0] > b.datasets[0].label.split(", ")[0]
      )
        return 1;
      else if (
        a.datasets[0].label.split(", ")[0] < b.datasets[0].label.split(", ")[0]
      )
        return -1;

      return 0;
    });
    this.plantGapClosureData.sort((a, b) => {
      if (
        a.datasets[0].label.split(", ")[1] > b.datasets[0].label.split(", ")[1]
      )
        return 1;
      else if (
        a.datasets[0].label.split(", ")[1] < b.datasets[0].label.split(", ")[1]
      )
        return -1;
      return 0;
    });
    this.plantGapClosureData.forEach((spiderData, index) => {
      console.log(spiderData)
      let plantChartData = Helper.getPlantDataSpider();
      let idToSet1 = "spider-chart" + index;
      let datasetToGet1 = spiderData;
      plantChartData.data.datasets[0].label = "Current Maturity Level";
      plantChartData.data.datasets[1].label = "Target Maturity Level";
      plantChartData.data.datasets[0].data = datasetToGet1.datasets[0].data;
      plantChartData.data.datasets[1].data = datasetToGet1.datasets[1].data;
      plantChartData.data.labels = spiderData.label;
      Chart.register(ChartJsPluginDataLabels);

      let ctx1 = document.getElementById(idToSet1);
      let obj1 = new Chart(ctx1, plantChartData);
      console.log(obj1)
    });
  },
  methods: {
    randomColor() {
      // Generate a random 2 digit hex number, padded with a 0 if necessary
      const part = () =>
        // Math.floor(Math.random() * 256)
        Math.floor(helper.getRandom() * 256)
          .toString(16)
          .padStart(2, '0');
      const r = part();
      const g = part();
      const b = part();
      return [
        "rgba(" + r + ", " + b + ", " + g + ", 0.2)",
        "rgb(" + r + ", " + b + ", " + g + ")",
      ];
    },
  },
};
</script>

<style scoped>
.marginClass {
  margin: 2rem;
}

.each-chart {
  width: 49.5%;
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
}

.each-chart-for-2 {
  width: 49.5%;
  border: 1px solid #ccc;
}

.each-chart-for-1 {
  width: 98%;
  border: 1px solid #ccc;
}

.d-flex {
  display: flex;
  justify-content: space-around;
}

.title {
  font: 1.33rem "BarlowR", sans-serif;
}

.principle-list {
  font: 1rem "BarlowR", sans-serif;
  flex: 50%;
}

.principle-list-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sample-list {
  display: flex;
  flex-direction: column;
}
</style>