export const plantChartData = {
  type: "radar",
  data: {
    labels: [],
    datasets: [
      {
        label: "My First Dataset",
        data: [],
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132)",
        pointBackgroundColor: "rgb(255, 99, 132)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 99, 132)"
      },
      {
        label: "My First Dataset",
        data: [],
        fill: true,
        backgroundColor: "rgba(255, 193, 7, 0.2)",
        borderColor: "rgb(255, 193, 7)",
        pointBackgroundColor: "rgb(255, 193, 7)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 193, 7)"
      }
    ]
  },
  options: {
    responsive: true,
    elements: {
      line: {
        borderWidth: 3
      },
      point: {
        radius: 10
      }
    },
    scales: {
      r: {
        max: 5,
        min: 0,
        ticks: {
          stepSize: 1
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        formatter: (val) => {
          console.log(val);
          if (val) return `${val.toLocaleString()}`;
          return ``;
        },
        labels: {
          value: {
            color: "#004ea8"
          }
        }
      }
    }
  }
};

export default plantChartData;
