<template>
  <div class="menu-body" v-if="$oidc.isAuthenticated &&
    $oidc.userProfile &&
    $oidc.userProfile.roles &&
    $oidc.userProfile.roles.length > 0
    ">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="outer-block">
      <div class="tab-titles">
        <div class="primary-color title-text"></div>

        <div class="button-dd">
          <div class="download-view action-btn" @click="downloadPdf">
            <img alt="download" src="../../../assets/download_icon.svg" />
          </div>
          <div class="funnel-con">
            <div class="action-btn primary-color" v-b-modal.action-filter>
              <b-icon icon="funnel"></b-icon>
            </div>
          </div>
          <b-modal id="action-filter" ref="my-modal" :hide-header="true" :hide-footer="true" dialog-class="right">
            <ActionItemFilter :hideModal="hideModal" :noOfFilters="noOfFilters" :filterArray="filterArray"
              :applyFilter="applyFilter" :resetFilter="resetFilter" :changedFilter="changedFilter"
              :filterSelected="filterSelected" :onSelect="onSelect" :onRemove="() => { }" />
          </b-modal>
        </div>
      </div>
      <!-- Spider Chart -->
      <SpiderChart id="chart" v-if="dataStatus && spiderData.length" :plantGapClosureData="spiderData" />
      <div class="no-data" v-else-if="dataStatus && !spiderData.length">
        <img alt="no data" src="../../../assets/No_Data.svg" />
        <span class="ed-date">Please modify filters</span>
      </div>
      <!-- Spider Chart -->
      <div id="print-chart"></div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import Api from "@/common/constants/api";
import ApiCalls from "@/common/api/apiCalls";
import ActionItemFilter from "@/components/Dashboard/ActionItemFilter.vue";
import SpiderChart from "./SpiderChart.vue";
import SpiderChartReport from "@/common/helper/SpiderChartReport.vue";
import { mapGetters } from "vuex";
import Role from "@/common/constants/roles";
export default {
  name: "GapClosureDashboard",
  data() {
    return {
      showLoader: false,
      spiderData: [],
      roles: Role,
      dataStatus: false,
      plantDataBackup: {},
      noOfFilters: 2,
      filterArray: [],
      filterSelected: [],
      plantList: [],
      totalCallCount: 0,
      applyFilterSpider: false,
      pillarList: [],
      isPlantUser: false,
    };
  },
  mixins: [SpiderChartReport],
  components: {
    Breadcrumb,
    Loader,
    ApiCalls,
    Api,
    ActionItemFilter,
    SpiderChart,
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 3,
      subMenu: 4,
    });
    this.$store.dispatch("breadcrumb", {
      // title: "Maturity Level",
      title: "Spiderweb View",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "Reports / ", primary: true, redirect: "/" },
        // { name: "Maturity Level", primary: false },
        { name: "Spiderweb View", primary: false },
      ],
    });
  },
  created() {
    this.showLoader = true;
    this.filterArray = [];
    this.filterSelected = this.filterArray;
    this.getPlantList();
    if (
      this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
      this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER) ||
      this.$oidc.userProfile.roles.includes(this.roles.PLANTSUBPILLARLEADER) ||
      this.$oidc.userProfile.roles.includes(this.roles.USER) ||
      this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER)
    )
      this.isPlantUser = true;
    else this.isPlantUser = false;
  },
  computed: {
    ...mapGetters(["loggedInUserData"]),
  },
  methods: {
    downloadPdf() {
      this.download = true;
      this.showLoader = true;
      let length =
        this.spiderData.length - 1 > 14 ? 14 : this.spiderData.length - 1;
      this.pdfMakeCall('spider', length);
      setTimeout(() => {
        this.showLoader = false;
      }, 10000);
    },
    getPlantList() {
      ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
        let arr = [];
        // console.log(data.data)
        this.plantDataBackup.plantList = data.data;
        // this.plantDataBackup.regionList = [];
        // data.data.forEach(element => {
        //   this.plantDataBackup.regionList.forEach(region=>{

        //   })
        // });
        arr = data.data.map((val) => {
          if (val.plantId) return val.plantId;
        });
        arr = arr.filter((ele) => {
          if (ele) return ele;
        });
        this.plantList = arr;
        this.getPillarList();
      });
    },
    getPillarList() {
      ApiCalls.httpCallDetails(Api.GETPILLARDROPDOWNLIST, "get").then(
        (data) => {
          let arr = [];
          this.plantDataBackup.pillarList = data.data;

          arr = data.data.map((val) => {
            if (val.active == "Y")
              return { id: val.pillarId, code: val.pillarName };
          });
          arr = arr.filter((ele) => {
            if (ele) return ele;
          });
          this.pillarList = arr;
          this.totalCallCount = this.plantList.length * this.pillarList.length;
          if (this.isPlantUser && this.loggedInUserData.plantId && this.loggedInUserData.plantId !== 0) {
            this.totalCallCount = 1 * this.pillarList.length;
            for (const element of this.pillarList)
              this.getSpiderData(
                Number(this.loggedInUserData.plantId),
                element
              );
          } else {
            this.showLoader = false;
            this.dataStatus = true;
          }
          this.setFilter();
        }
      );
    },

    getSpiderData(plant, pillar) {
      ApiCalls.httpCallDetails(
        Api.GETSPIDERDATA + "Plantid=" + plant + "&PillarId=" + pillar.id,
        "get"
      ).then((data) => {
        let obj = {
          label: [],
          datasets: [
            {
              label: "",
              data: [],
              fill: true,
            },
            {
              label: "",
              data: [],
              fill: true,
            },
          ],
        };
        if (data.data.principleNames.length) {
          obj.principleList = data.data.principleNames.map((val, i) => {
            return val;
          });

          obj.label = data.data.principleNames.map((val, i) => {
            let principleNumValue = val.split(".")[0];
            return "#" + principleNumValue;
          });


          //pillar.name
          obj.datasets[0].pillarN = pillar.code;
          obj.datasets[0].pillarId = pillar.id;
          obj.datasets[0].label =
            data.data.labels.length > 0
              ? data.data.labels[0].plantName +
              ", " +
              data.data.labels[0].regionName
              : null;
          obj.datasets[0].data = data.data.data.map((val) => val.maturityLevel);

          obj.datasets[1].label =
            data.data.labels.length > 0
              ? data.data.labels[1].plantName +
              ", " +
              data.data.labels[1].regionName
              : null;
          obj.datasets[1].data = data.data.data.map(
            (val) => val.targetMaturityLevel
          );
          obj["plantId"] = data.data.plantId;
          obj["pillarId"] = data.data.pillarId;
          obj["regionId"] = data.data.regionId;
          this.spiderData.push(obj);
        }
        this.totalCallCount--;
        if (this.totalCallCount === 0) {
          this.showLoader = false;
          this.dataStatus = true;
        }
      });
    },
    byPlant() {
      this.spiderData.sort((a, b) => {
        if (a.datasets[0].pillarId > b.datasets[0].pillarId) return 1;
        else if (a.datasets[0].pillarId < b.datasets[0].pillarId) return -1;

        return 0;
      });
      this.spiderData.sort((a, b) => {
        if (
          a.datasets[0].label.split(", ")[0] >
          b.datasets[0].label.split(", ")[0]
        )
          return 1;
        else if (
          a.datasets[0].label.split(", ")[0] <
          b.datasets[0].label.split(", ")[0]
        )
          return -1;

        return 0;
      });
      this.spiderData.sort((a, b) => {
        if (
          a.datasets[0].label.split(", ")[1] >
          b.datasets[0].label.split(", ")[1]
        )
          return 1;
        else if (
          a.datasets[0].label.split(", ")[1] <
          b.datasets[0].label.split(", ")[1]
        )
          return -1;
        return 0;
      });

      let index = this.spiderData.findIndex(
        (val) => Number(val.plantId) === Number(this.loggedInUserData.plantId)
      );
      if (index != -1) {
        let len = this.spiderData.filter(
          (val) => Number(val.plantId) === Number(this.loggedInUserData.plantId)
        );
        let first = this.spiderData.splice(index, len.length);
        this.spiderData = first.concat(this.spiderData);
      }
      this.dataStatus = true;
    },

    setFilter() {
      let plantNameArray = [];
      plantNameArray = this.plantDataBackup.plantList.map((value) => {
        let obj;
        if (this.isPlantUser) {
          obj = {
            value: value.plantId,
            label: value.plantName + ", " + value.regionName,
            checked:
              value.plantId === Number(this.loggedInUserData.plantId)
                ? true
                : false,
          };
        } else
          obj = {
            value: value.plantId,
            label: value.plantName + ", " + value.regionName,
            checked: false,
          };
        return obj;
      });

      plantNameArray.sort((a, b) => {
        if (a.value < b.value) {
          return -1;
        } else if (a.value > b.value) {
          return 1;
        }
      });

      if (this.isPlantUser) {
        let index = plantNameArray.findIndex(
          (val) => Number(val.value) === Number(this.loggedInUserData.plantId)
        );
        let first = plantNameArray.splice(index, 1);
        plantNameArray.unshift(first[0]);
      }
      plantNameArray.unshift({
        value: 0,
        label: "All",
        checked: false,
      });

      plantNameArray = plantNameArray.filter(function (item, pos) {
        return item.label !== null && plantNameArray.indexOf(item) == pos;
      });

      let pillarListArray = [];
      pillarListArray = this.plantDataBackup.pillarList.map((value) => {
        let obj = {
          value: value.pillarId,
          label: value.pillarName,
          checked: true,
        };
        return obj;
      });

      pillarListArray = pillarListArray.filter(function (item, pos) {
        return item !== null && pillarListArray.indexOf(item) == pos;
      });

      pillarListArray.push({
        value: 0,
        label: "All",
        checked: true,
      });
      pillarListArray.sort((a, b) => {
        if (a.value < b.value) {
          return -1;
        } else if (a.value > b.value) {
          return 1;
        }
      });

      this.filterArray = [
        {
          labelName: "Plant Name",
          arrayValue: plantNameArray,
          id: "PlantName",
          multiple: true,
        },
        {
          labelName: "Pillar Name",
          arrayValue: pillarListArray,
          id: "PillarName",
          multiple: true,
        },
      ];
      this.filterSelected = this.filterArray;
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    applyFilter() {
      let filterSelected = this.filterSelected;
      console.log(filterSelected)
      if (this.filterSelected[0].arrayValue.length > 0) {
        this.applyFilterSpider = true;
        this.plantList = this.filterSelected[0].arrayValue.map((plant, i) => {
          if (plant.checked) return plant.value;
        });
        this.plantList = this.plantList.filter((ele) => {
          if (ele) return ele;
        });
        this.pillarList = this.filterSelected[1].arrayValue.map((pillar, i) => {
          if (pillar.checked) return { id: pillar.value, code: pillar.label };
        });
        this.pillarList = this.pillarList.filter((ele) => {
          if (ele) return ele;
        });
        if (this.plantList.length && this.pillarList.length) {
          this.showLoader = true;
          this.dataStatus = false;
        }
        setTimeout(() => {
          this.spiderData = [];
          this.totalCallCount = this.plantList.length * this.pillarList.length;
          for (const element of this.plantList) {
            for (const el of this.pillarList) {
              this.getSpiderData(element, el);
            }
          }
          if (this.plantList.length === 0 || this.pillarList.length === 0)
            this.showLoader = false;
        }, 100);
      }
      this.hideModal();
    },
    resetFilter() {
      for (const key in this.filterSelected) {
        if (Object.hasOwnProperty.call(this.filterSelected, key)) {
          this.filterSelected[key].arrayValue.forEach((element) => {
            element.checked = true;
          });
        }
      }
      if (!this.applyFilterSpider) this.applyFilter();
      this.hideModal();
    },
    changedFilter(event) {
      let filterSelectedC = [];
      this.filterArray.map((val, index) => {
        if (
          val.id === event.target.id &&
          event.target.value !== val.id &&
          val.arrayValue.includes(event.target.value)
        ) {
          let temp = {
            id: val.id,
            arrayValue: [event.target.value],
            labelName: val.labelName,
          };
          filterSelectedC.push(temp);
          return filterSelectedC;
        } else if (
          val.id === event.target.id &&
          val.id === event.target.value
        ) {
          let temp = {
            id: val.id,
            arrayValue: val.arrayValue,
            labelName: val.labelName,
          };
          filterSelectedC.push(temp);
          return filterSelectedC;
        } else if (
          val.id !== event.target.id &&
          event.target.value !== val.id &&
          val.arrayValue.length ===
          this.filterSelected[index]["arrayValue"].length
        ) {
          let temp = {
            id: val.id,
            arrayValue: val.arrayValue,
            labelName: val.labelName,
          };
          filterSelectedC.push(temp);
          return filterSelectedC;
        } else {
          filterSelectedC.push(this.filterSelected[index]);
        }
      });
      this.filterSelected = filterSelectedC;
    },
    onSelect(option, index) {
      let indexLocal = this.filterSelected[index].arrayValue.findIndex(
        (item) => item.label == option.label
      );
      this.filterSelected[index].arrayValue[indexLocal].checked =
        !this.filterSelected[index].arrayValue[indexLocal].checked;
      if (option.label == "All" && indexLocal === 0) {
        if (option.checked) {
          for (
            let i = 1;
            i < this.filterSelected[index].arrayValue.length;
            i++
          ) {
            this.filterSelected[index].arrayValue[i].checked = true;
          }
        } else {
          for (
            let i = 1;
            i < this.filterSelected[index].arrayValue.length;
            i++
          ) {
            this.filterSelected[index].arrayValue[i].checked = false;
          }
        }
      }
    },
  },
};
</script>
<style scoped>
#chart {
  padding: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.outer-block {
  background-color: #fff;
  border-radius: 6px;
}

.tab-titles {
  padding: 2rem 2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-options {
  margin-right: 1rem;
  display: flex;
}

.tab-option-text {
  font: 1.16rem "BarlowR", sans-serif;
  cursor: pointer;
}

.in-action {
  margin-right: 1.688em;
}

.tab-active-option {
  font: 1.18rem "BarlowM", sans-serif;
}

.active-tab-scroll-hr {
  border-top: 3px #274ba7 solid;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  opacity: 1 !important;
  margin-top: 1rem;
}

.select-dd {
  width: 10%;
}

.button-dd {
  display: inline-flex;
}

.funnel-con {
  /* border-radius: 2rem;
  border: 1px solid #274ba7;
  margin-left: 1rem;
  font-size: 1.33rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; */
  font-size: 1.33rem;
}

.sample-span {
  margin-right: 1rem;
  font: 1.08rem "BarlowM", sans-serif;
  color: #313131;
}

/* .sample-list {
  display: flex;
  align-items: center;
  justify-content: center;
} */
.title-text {
  font: 1.33rem "BarlowSb", sans-serif;
  padding: 0 0 2rem 0;
}

.data-table tbody {
  font-size: 0.875em;
}

#reports-gc-data-table th div {
  color: #313131;
}

#reports-gc-data-table tbody tr:nth-child(odd) {
  background-color: #fafafa !important;
}

#reports-gc-data-table tbody tr:nth-child(even):hover {
  background-color: #fff !important;
}

#reports-gc-data-table tr td:first-child {
  padding-left: 1.25em !important;
}

#reports-gc-data-table tr th:first-child {
  padding-left: 1.25em !important;
}

.download-view {
  border-radius: 2rem;
  border: 1px solid #274ba7;
  margin-left: 1rem;
  font-size: 1.33rem;
  margin-right: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.download-view:hover {
  background: #e3e8f7;
}

.action-btn {
  border: 1px #274ba7 solid;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  cursor: pointer;
}
</style>